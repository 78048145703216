import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import ConfirmationOrDecisionModal from "./ConfirmationOrDecisionModal";
import {NO, REPURPOSE_BODY, REPURPOSE_HEADER, YES} from "../utils/constants";

import {setCondition} from "../actions/conditionActions";
import {setRepurposedFlag} from "../actions/repurposeActions";
import {loadImageList} from "../actions/damageImageActions";
import {setAnnouncements} from "../actions/announcementsActions";

function RepurposeModal(props) {

    const [isModalOpen, setOpenModal] = useState(false)

    useEffect(() => {
        if (!!props.repurpose && !props.repurpose.repurposed) {
            setOpenModal(true);
        }
    }, [props.repurpose, props.repurposeAnnouncements]);

    const handleContinue = async () => {
        try {
            await props.setCondition(props.repurpose, true);
            await props.setAnnouncements(props.repurposeAnnouncements);
            await props.setRepurposedFlag("Y");
            const newRepurpose = {...props.repurpose, repurposed: 'Y'};
            await props.loadImageList(newRepurpose);
            setOpenModal(false);
        } catch (err) {
            console.log("Error setting repurposed condition:", err);
        }
    }

    const handleClose = () => {
        props.setRepurposedFlag("N");
        setOpenModal(false);
    }

    return (
        <ConfirmationOrDecisionModal
            shouldShowModal={isModalOpen}
            modalHeader={REPURPOSE_HEADER}
            modalBody={REPURPOSE_BODY}
            modalType="repurpose"
            confirmationText={YES}
            cancelText={NO}
            handleClose={handleClose}
            handleContinue={handleContinue}
        />
    )
}

function mapStateToProps({repurpose, repurposeAnnouncements}) {
    return {repurpose, repurposeAnnouncements};
}

const mapDispatchToProps = {
    setCondition, setAnnouncements, setRepurposedFlag, loadImageList
};

export default connect(mapStateToProps, mapDispatchToProps)(RepurposeModal);

