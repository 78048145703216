import React from 'react';
import Button from "@prism/button";

const AuthErrorPage = () => {

    const handleTryAgain = () => {
        window.location.href = '/logout';
    }

    return (
        <div style={{textAlign: 'center', marginTop: '20%'}}>
            <h1>Unified Authorization Service is Unavailable</h1>
            <p>Sorry, we’re unable to connect to the authorization service to get User Details. Please Try Again.</p>
            <Button className="input-group-text pr-4 pl-4 m-0"
                    color="secondary"
                    id="tryAgainButton" onClick={handleTryAgain}>Try Again</Button>
        </div>
    );
};

export default AuthErrorPage;