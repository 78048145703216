import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import App from '../components/App';
import {APPROVALS_API_URL} from "../config/env";
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "../index";
import AuthErrorPage from "../components/AuthErrorComponent";

const Routes = ({ store }) => (
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/">
                    <PersistGate loading={null} persistor={persistor}>
                        <App name="App" ref={(element) => {
                            console.log("in js root path; exporting app to window", {window: window, appComponent: element})
                            window.appComponent = element
                        }}/>
                    </PersistGate>
                </Route>
                {
                    <Route exact path="/logout" render={() => {window.location.href=`${APPROVALS_API_URL}/logout?application=smart_inspect`;}} />}
                    <Route exact path="/401" render={() => {window.location.href="https://publish.manheim.com/en/error_pages/do-not-have-permission.html";}} />
                    <Route exact path="/auth-failure" component={AuthErrorPage}/>
            </Switch>
        </Router>
    </Provider>
);


export default Routes